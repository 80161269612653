<template>
  <div class="home">
    <LoginRequired />
    <section class="hero is-bold" v-if="account">
      <div v-if="beneficiary" class="hero-body">
        <div class="container has-text-white">
          <div class="level is-aligned-start">
            <div class="level-left is-half">
              <div class="level-item is-level-column is-aligned-start is-justified-start">
                <h1 class="title is-size-2 has-text-white">
                  Hej, {{ beneficiary.name }}
                </h1>
                <p class="subtitle has-text-white is-size-6">
                  Här kan du se ditt pensionskapital, placering och utveckling.
                </p>
                <div v-if="beneficiary" class="subtitle has-text-white is-size-7 level is-flex" style="align-items: flex-end; margin-top: 10px;">
                  <p class="level-left is-half" style="white-space: nowrap; margin-right: 0.75em;">
                    {{ beneficiary.name }}<br />
                    {{ beneficiary.address }}<br />
                    {{ beneficiary.postalcode }} {{ beneficiary.postaltown }}<br />
                  </p>
                  <p class="level-right is-half" style="white-space: nowrap;">
                    Arbetsgivare<br />
                    {{ beneficiary.companies.map(c => c.companyName).join(', ') }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="beneficiary" class="level-right" style="flex-direction: column; align-items: flex-start">
              <div class="level-item is-level-column is-aligned-start is-justified-start mb-3" v-if="beneficiary.delegates">
                <p class="capital-header is-size-7">Rådgivare</p>
                <p class="is-size-6">
                  {{ beneficiary.delegates.map(c => `${c.delegateName}`).join(', ') }}
                </p>
              </div>
              <div class="level-item is-level-column is-aligned-start is-justified-start">
                <p class="capital-header">Total kapitalvärde</p>
                <p class="is-size-2">{{ totalWorthInCents | amountInCentstoDisplay }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="currentData && currentData.delegate" class="hero-body">
        <div class="container has-text-white">
          <div class="level is-aligned-start">
            <div class="level-left is-half">
              <div class="level-item is-level-column is-aligned-start is-justified-start">
                <h1 class="title is-size-2 has-text-white">
                  Hej {{ currentData.delegate.delegateName }}
                </h1>
                <p class="subtitle has-text-white is-size-6">
                </p>
                <div class="subtitle has-text-white is-size-7 level is-flex" style="align-items: flex-end; margin-top: 10px;">
                  <p class="level-left is-half" style="white-space: nowrap; margin-right: 0.75em;">
                    {{ currentData.delegate.delegateCompanyName }}<br>
                    {{ currentData.delegate.delegatePhone }}<br>
                    {{ currentData.delegate.delegateEmail }}
                  </p>
                </div>
              </div>
            </div>
            <div class="level-right" style="flex-direction: column; align-items: flex-start">
              <div class="level-item is-level-column is-aligned-start is-justified-start">
              </div>
              <div class="level-item is-level-column is-aligned-start is-justified-start">
                  <router-link :to="advisorPensionablesUrl" class="button is-size-6"><font-awesome-icon :icon="['fal', 'user-friends']" class="is-size-4 mr-2" />Klicka här för att se pensionsberättigade</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="currentData && currentData.employerContactPerson && currentData.employerContactPerson.length > 0" class="hero-body">
        <div class="container has-text-white">
          <div class="level is-aligned-start">
            <div class="level-left is-half">
              <div class="level-item is-level-column is-aligned-start is-justified-start">
                <h1 class="title is-size-2 has-text-white">
                  Hej {{ currentData.employerContactPerson[0].contactEmployers[0].contactName }}
                </h1>
                <p class="subtitle has-text-white is-size-6">
                </p>
                <div class="subtitle has-text-white is-size-7 level is-flex" style="align-items: flex-end; margin-top: 10px;">
                  <p class="level-left is-half" style="white-space: nowrap; margin-right: 0.75em;">
                  </p>
                  <p class="level-right is-half" style="white-space: nowrap;">
                  </p>
                </div>
              </div>
            </div>
            <div class="level-right" style="flex-direction: column; align-items: flex-start">
              <div class="level-item is-level-column is-aligned-start is-justified-start">
              </div>
              <div class="level-item is-level-column is-aligned-start is-justified-start">
                  <router-link :to="ecpPensionablesUrl" class="button is-size-6"><font-awesome-icon :icon="['fal', 'user-friends']" class="is-size-4 mr-2" />Klicka här för att se pensionsberättigade</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-medium has-light-border-top" v-if="error">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-7">
            <b-message title="Ett fel uppstod" type="is-danger" aria-close-label="Stäng meddelande">
              Ett fel uppstod vid hämtning av uppgifter, testa igen om ett par minuter. Var god kontakta Stifta om problemet kvarstår
            </b-message>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-medium has-light-border-top" v-if="sortedLedgers && sortedLedgers.length > 0">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-6-desktop is-7-tablet has-text-centered">
            <h2 class="is-size-4 has-text-weight-bold has-text-centered has-text-black">Kapitalutveckling
              <select v-if="showOverTime" v-model="pickedLedger">
                <option v-for="ledger in sortedLedgers"
                  :value="ledger"
                  :key="`${ledger.forMonth.year}-${ledger.forMonth.month}`">
                  {{ monthToHuman(ledger.forMonth) }}
                </option>
              </select>
            </h2>
<!--
            <p class="has-text-black">Här kan du välja att se din total kapitalutveckling över månader eller år samt (prognostiserad) pensionsutbetalning på akutellt kapitalvärde.</p>
-->
          </div>
        </div>
        <div class="columns is-centered pb-5" v-if="pickedLedger">
          <div class="border-box has-text-centered">
            <h3 class="is-size-6">Totalt kapitalvärde *</h3>
            <p class="is-size-7"><!--1 jan 2009 <font-awesome-icon :icon="['fas', 'minus']" class="capital-dash" /> -->{{ firstOfActiveMonth }}</p>
            <p class="is-size-4 has-text-weight-bold has-text-black is-relative ml-8">
              {{ totalWorthInCentsFor(pickedLedger) | amountInCentstoDisplay }}
              <!--<b class="capital-change green"> {{ 13 > 0 ? `+${13}%` : `${13}%` }} </b>-->
            </p>
            <div class="columns mt-4 has-text-left" style="border-left: 0" v-if="delegatePhone">
              <div class="column is-flex" style="justify-content: center">
                <div class="is-relative" style="top: -5px;">
                  <img src="@/assets/login-background.jpg" class="profile-image">
                  <font-awesome-icon :icon="['fas', 'phone-alt']" :style="{position: 'absolute', left: '1.25em', top: '1.25em', color:'gray', filter: 'drop-shadow(1px 1px 0px #fff) drop-shadow(-1px 1px 0px #fff) drop-shadow(1px -1px 0px #fff) drop-shadow(-1px -1px 0px #fff)'}"/>
                </div>
                <div class="is-paddingless pl-2 has-text-gray">
                  <p class="is-size-7">Frågor eller funderingar? Ring mig!</p>
                  <p class="is-size-7 has-text-weight-bold">{{ delegatePhone }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="border-box has-text-centered" v-if="(pickedLedger.payLastday && pickedLedger.payFirstday) || pickedLedger.payOneoff">
            <template v-if="pickedLedger.payOneoff">
            <h3>Pensionsutbetalning</h3>
            <p class="is-size-7">Brutto. {{ dateToHuman(pickedLedger.payOneoff) }}</p>
            </template>
            <template v-else-if="pickedLedger.payLastday && pickedLedger.payFirstday">
            <h3>Pensionsutbetalning</h3>
            <p class="is-size-7">Brutto. {{ dateToHuman(pickedLedger.payFirstday) }} tom. {{ dateToHuman(pickedLedger.payLastday) }}</p>
            </template>
            <p class="is-size-4 has-text-weight-bold has-text-black is-relative ml-8">
              {{ pickedLedger.payInCent | amountInCentstoDisplay }}
            </p>
            <!--
            <button class="button payout is-size-6"><font-awesome-icon :icon="['fal', 'life-ring']" class="is-size-4 mr-2" />Så aktiverar du utbetalning</button>
            -->
          </div>
          <!-- disabled -->
          <div class="border-box has-text-centered" v-if="false">
            <h3>Tillgängligt kapital</h3>
            <p class="is-size-7">{{ dateToHuman(pickedLedger.lastUpdate) }}</p>
            <p v-if="pickedLedger.totalExcessInCent > 0" class="is-size-4 has-text-weight-bold has-text-black is-relative ml-8">
              {{ pickedLedger.totalExcessInCent | amountInCentstoDisplay }}
            </p>
            <p v-else class="is-size-4 has-text-weight-bold has-text-black is-relative ml-8">
              {{ 0 | amountInCentstoDisplay }}
            </p>
            <!--
            <button class="button payout is-size-6"><font-awesome-icon :icon="['fal', 'life-ring']" class="is-size-4 mr-2" />Så aktiverar du utbetalning</button>
            -->
          </div>
        </div>
      </div>

      <div class="container mt-6" v-if="showOverTime && beneficiary && beneficiary.ledger && beneficiary.ledger.length > 1">
        <div class="columns is-centered">
          <div class="column is-6-desktop is-7-tablet has-text-centered">
            <h2 class="is-size-4 has-text-weight-bold has-text-centered has-text-black">Kapitalutveckling över tid</h2>
          </div>
        </div>
        <div class="columns is-centered">
          <BarChart v-if="showChart && chartdata" ref="chart" class="column is-full" :chartData="chartdata" :options="chartoptions" :height="200"/>
        </div>
      </div>

      <div class="container mt-6" v-if="capitalplacement !== null">
        <div class="columns">
          <div class="column is-6-desktop is-7-tablet">
            <h2 class="is-size-4 has-text-weight-bold has-text-black">Kapitalplacering</h2>
          </div>
        </div>
        <div class="columns">
          <TableRender :headers="[
            { key: 'placement', title: 'Placering', is_link: true },
            { key: 'value', title: 'Värde', type: 'bold-money'},
         /* { key: 'total_value', title: 'Total värdeutveckling', type: 'money' }, */
            ...(showOverTime ? [
              { key: 'months_12', title: '12 månader', type: 'money'},
              { key: 'months_3', title: '3 månader', type: 'money' },
              { key: 'months_1', title: '1 månad', type: 'money' }
            ] : [])
          ]"
          :content="capitalplacement"/>
        </div>
      </div>
      <div class="container mt-6">
        <div class="columns is-centered">
          <div class="column">
            <p class="is-size-7 footnote" id="footnote-1">* SGP uppdateras månadsvis. SGP Flex uppdateras kvartalsvis.</p>
          </div>
        </div>
      </div>
<!--
      <div class="container mt-6">
        <div class="columns">
          <div class="column is-6-desktop is-7-tablet">
            <h2 class="is-size-4 has-text-weight-bold has-text-black">Pensionsutfästelser</h2>
          </div>
        </div>
        <div class="columns">
          <TableRender :headers="[
            { key: 'employeer', title: 'Arbetsgivare', is_link: true },
            { key: 'capital', title: 'Kapitalvärde på utfästelse', type: 'bold-money' },
            { key: 'pension_supplement', title: 'Pensionstillägg' },
            { key: 'pension_age', title: 'Pensionsålder' }
          ]"
          :content="pledges"/>
        </div>
      </div>
-->
    </section>
    <pre style="font-size: 8px; display: none;">{{ JSON.stringify(beneficiary, null, 2) }}</pre>
  </div>
</template>

<script>
import Vue from 'vue'
import leftPad from 'left-pad'
import moment from 'moment'

import LoginRequired from '@/components/LoginRequired'
import BarChart from '@/components/BarChart'
import TableRender from '@/components/TableRender'

export default Vue.extend({
  name: 'home',
  components: {
    LoginRequired,
    BarChart,
    TableRender
  },
  data () {
    return {
      pickedLedger: null,
      showChart: true
    }
  },
  prototype: {
    moment
  },
  methods: {
    splitAddress (address) {
      return address.split(',').map(part => part.trim(' '))
    },
    monthToHuman (date) {
      return leftPad(date.year, 4, '0') + '-' +
        leftPad(date.month, 2, '0')
    },
    dateToHuman (date) {
      return leftPad(date.year, 4, '0') + '-' +
        leftPad(date.month, 2, '0') + '-' +
        leftPad(date.day, 2, '0')
    },
    dateToMoment (date) {
      if (!date.day) {
        return moment([parseInt(date.year), parseInt(date.month) - 1])
      }
      return moment([parseInt(date.year), parseInt(date.month) - 1, parseInt(date.day)])
    },
    totalWorthInCentsFor (ledger) {
      if (ledger.rows === null) {
        return null
      }
      const ledgerRows = ledger.rows.map(row => {
        return Math.floor(row.amountInCent / 100) * 100
      })
      let total = 0
      ledgerRows.forEach(amountInCent => {
        total += amountInCent
      })
      return total
    }
  },
  watch: {
    sortedLedgers: {
      handler () {
        if (!this.sortedLedgers || this.sortedLedgers.length === 0) {
          this.pickedLedger = null
        } else {
          this.pickedLedger = this.sortedLedgers[this.sortedLedgers.length - 1]
        }
      },
      deep: true,
      immediate: true
    },
    chartdata: {
      handler () {
        this.showChart = false
        this.$nextTick(() => {
          this.showChart = true
        })
      },
      deep: true
    }
  },
  asyncComputed: {
    currentData: {
      async get () {
        if (!this.$store.state.profile.raw) {
          return null
        }
        const selectedTrust = this.$store.state.profile.raw.find(r => {
          return r.TrustSlug === this.$route.params.trust
        })

        if (this.$route.query.b) {
          const specTrust = await this.$store.state.profile.apiClient.getFor(this.$route.params.trust, this.$route.query.b)
          return specTrust.data
        }

        if (this.$route.query.p) {
          const specTrust = await this.$store.state.profile.apiClient.getFor(this.$route.params.trust, this.$route.query.p)
          return specTrust.data
        }

        return selectedTrust
      },
      default () {
        return null
      }
    }
  },
  computed: {
    showOverTime () {
      return this.currentData.TrustSlug !== 'sgpskarpejigång'
    },
    firstOfActiveMonth () {
      if (!this.pickedLedger || !this.pickedLedger.forMonth) {
        return '-'
      }
      const year = this.pickedLedger.forMonth.year
      const month = this.pickedLedger.forMonth.month - 1
      return moment().set('year', year).set('month', month).startOf('month').format('YYYY-MM-DD')
    },
    capitalplacement () {
      const valuePointers = new Map()

      const stiftelseNames = new Map()

      if (this.sortedLedgers === null) {
        return null
      }

      this.sortedLedgers.forEach((ledger) => {
        const monthsPassed = moment().diff(this.dateToMoment(ledger.forMonth), 'months')
        if (ledger.rows === null) {
          return
        }
        ledger.rows.forEach((row) => {
          const current = Math.floor(row.amountInCent / 100)
          const stiftelseId = row.stiftelse.from_new_profile && row.stiftelse.old_id !== '' ? row.stiftelse.old_id : row.stiftelse.id
          const valuePointer = valuePointers.get(stiftelseId) || new Map()
          stiftelseNames.set(stiftelseId, row.stiftelse.name)

          valuePointer.set('lastForMonth', ledger.forMonth)

          valuePointer.set(0, current)
          if (monthsPassed > 1) {
            valuePointer.set(1, current)
          }
          if (monthsPassed > 3) {
            valuePointer.set(3, current)
          }
          if (monthsPassed > 12) {
            valuePointer.set(12, current)
          }
          valuePointers.set(stiftelseId, valuePointer)
        })

        valuePointers.forEach((value, key) => {
          if (value.get('lastForMonth') === ledger.forMonth) {
            return
          }

          value.set(0, 0)
          if (monthsPassed > 1) {
            value.set(1, 0)
          }
          if (monthsPassed > 3) {
            value.set(3, 0)
          }
          if (monthsPassed > 12) {
            value.set(12, 0)
          }
          valuePointers.set(key, value)
        })
      })

      const placements = []

      valuePointers.forEach((value, key) => {
        if (value.get(0) === 0) {
          return
        }
        placements.push({
          placement: {
            is_link: false,
            value: stiftelseNames.get(key)
          },
          value: {
            value: value.get(0)
          },
          total_value: {
            value: value.get(0)
          },
          months_12: {
            value: value.get(0) - (value.get(12) || 0)
          },
          months_3: {
            value: value.get(0) - (value.get(3) || 0)
          },
          months_1: {
            value: value.get(0) - (value.get(1) || 0)
          }
        })
      })

      placements.push({
        placement: {
          is_link: false,
          value: 'Total'
        },
        value: {
          value: Math.floor(this.totalWorthInCents / 100)
        },
        total_value: {
          value: placements.reduce((cur, val) => cur + val.total_value.value, 0)
        },
        months_12: {
          value: placements.reduce((cur, val) => cur + val.months_12.value, 0)
        },
        months_3: {
          value: placements.reduce((cur, val) => cur + val.months_3.value, 0)
        },
        months_1: {
          value: placements.reduce((cur, val) => cur + val.months_1.value, 0)
        }
      })
      return placements.sort((a, b) =>
        a.placement.value.localeCompare(b.placement.value)
      )
    },
    pledges () {
      return [0, 0, 0, 0].reduce((arr, meta) => {
        arr.push({
          employeer: {
            href: '#',
            value: 'Plåtslagarna i Järna AB'
          },
          capital: {
            value: 200000
          },
          pension_supplement: {
            value: ''
          },
          pension_age: {
            value: '70 - 90'
          }
        })
        return arr
      }, [])
    },
    sortedLedgers () {
      if (!this.beneficiary || !this.beneficiary.ledger || this.beneficiary.ledger.length === 0) {
        return null
      }
      return [...this.beneficiary.ledger].sort((a, b) => {
        if (a.forMonth.year !== b.forMonth.year) {
          return a.forMonth.year - b.forMonth.year
        }
        if (a.forMonth.month !== b.forMonth.month) {
          return a.forMonth.month - b.forMonth.month
        }
        return a.forMonth.day - b.forMonth.day
      })
    },
    latestLedger () {
      if (this.sortedLedgers == null || this.sortedLedgers.length === 0) {
        return null
      }
      return this.sortedLedgers[this.sortedLedgers.length - 1]
    },
    totalWorthInCents () {
      if (this.ledgerRows === null) {
        return null
      }
      let total = 0
      this.ledgerRows.forEach(row => {
        total += row.amountInCent
      })

      return total
    },
    //
    //
    //
    error () {
      return this.$store.state.profile.error
    },
    account () {
      return (this.$store.getters.session != null) ? this.$store.getters.session.account : null
    },
    beneficiary () {
      return this.currentData ? this.currentData.beneficiary : null
    },
    delegatePhone () {
      return this.beneficiary.delegatePhone || '010-490 10 10'
    },
    fullAddress () {
      if (this.beneficiary === null || this.beneficiary.address === '') {
        return null
      }
      return this.beneficiary.address + ', ' + this.beneficiary.postalcode + ' ' + this.beneficiary.postaltown
    },
    ledgerRows () {
      if (this.latestLedger == null || this.latestLedger.rows == null) {
        return null
      }
      return this.latestLedger.rows.map(row => {
        /*
         * Viktigt, avrunda neråt!
         * Detta är pga. att värden som visas mot Användare ska reflektera vad
         * som visas i Avkastningsbeskedet.
         */
        row.amountInCent = Math.floor(row.amountInCent / 100) * 100
        return row
      })
    },
    ledgerLastUpdate () {
      if (this.latestLedger === null) {
        return null
      }
      return leftPad(this.latestLedger.lastUpdate.year, 4, '0') + '-' +
        leftPad(this.latestLedger.lastUpdate.month, 2, '0') + '-' +
        leftPad(this.latestLedger.lastUpdate.day, 2, '0')
    },
    chartdata () {
      if (!this.sortedLedgers) {
        return null
      }

      const rows = this.sortedLedgers.map(ledger => {
        let total = 0
        if (ledger.rows === null) {
          return null
        }
        ledger.rows.forEach(row => {
          if (row.amountInCent === 0) {
            return
          }
          total += Math.floor(row.amountInCent / 100)
        })

        return {
          x: leftPad(ledger.forMonth.year, 4, '0') + '-' +
             leftPad(ledger.forMonth.month, 2, '0'),
          y: total
        }
      }).filter(v => v !== null)

      // filter out leading rows with zero value
      while (rows.length > 0 && rows[0].y === 0) {
        rows.shift()
      }

      if (rows.length === 0) {
        return null
      }

      return {
        labels: rows.map(row => row.x),
        datasets: [
          {
            label: 'Kapital',
            steppedLine: 'after',
            backgroundColor: '#F5F5F5',
            firstData: rows[0].y,
            data: rows,
            barPercentage: 1,
            categoryPercentage: 1,
            fill: true
          }
        ]
      }
    },
    advisorPensionablesUrl () {
      let q = ''
      if (this.$route.query.p) {
        q += '?p=' + this.$route.query.p
      }
      return '/t/' + this.$route.params.trust + '/pensionables' + q
    },
    ecpPensionablesUrl () {
      let q = ''
      if (this.$route.query.p) {
        q += '?p=' + this.$route.query.p
      }
      return '/t/' + this.$route.params.trust + '/pensionables' + q
    },
    chartoptions () {
      const vue = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        showAllTooltips: true,
        tooltips: {
          yAlign: 'bottom',
          xAlign: 'left',
          caretSize: 0,
          cornerRadius: 0,
          position: 'topleft',
          bodyFontStyle: 'bold',
          callbacks: {
            label (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const current = dataset.data[tooltipItem.index].y
              const previous = tooltipItem.index > 0 ? dataset.data[tooltipItem.index - 1].y : dataset.firstData
              const value = vue.$decimal(tooltipItem.value).div(previous).sub(1).mul(100)

              if (tooltipItem.index === 0 || previous === 0) {
                return `${tooltipItem.value} kr`
              } else if (!previous || current > previous) {
                return '+' + value + '%'
              } else {
                return value + '%'
              }
            },
            labelTextColor (tooltipItem, chart) {
              const dataset = chart.data.datasets[tooltipItem.datasetIndex]
              const current = dataset.data[tooltipItem.index].y
              const previous = tooltipItem.index > 0 ? dataset.data[tooltipItem.index - 1].y : dataset.firstData

              if (tooltipItem.index === 0 || previous === 0) {
                return 'rgb(90, 90, 90)'
              } else if (!previous || current > previous) {
                return 'rgb(90, 90, 90)'
              } else {
                return 'red'
              }
            },
            title () {
              return ''
            }
          }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              display: false,
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }],
          xAxes: [{
            ticks: {
              fontStyle: 'bold'
              // fontSize: 16
            },
            gridLines: {
              color: '#EAEAEA',
              z: 10
            }
          }]
        }
      }
    }
  }
})
</script>

<style scoped lang="scss">
  $deep-sapphire: #012169;
  $lochmara: #0085CA;
  $calypso: #34657F;
  $silver-chalice: #A7A8AA;
  $black: #2a2c32;
  $gray: #737373;
  $white: #F5F5F5;

  .capital-change {
    position: absolute;
    margin: 0.75em 0 0 1em;
    padding: 0.25em 0.75em;
    color: $gray;
    font-size: 0.6rem;
  }
  .capital-change.green {
    background: #E9FAE5
  }

  .capital-change.red {
    background: #FAE5E5
  }

  .capital-dash {
    color: $silver-chalice;
    position: relative;
    top: 1px;
  }

  .has-text-gray {
    color: $gray;
  }

  .button.payout {
    border-radius: 0;
    background: $black;
    color: $white;
    margin-top: 1em;
  }

  .profile-image {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
  }

  .hero-body {
    background: $deep-sapphire;
    .container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 37px;
      line-height: 45px;
    }
    .subtitle {
      font-size: 14px;
    }
  }
  .is-justified-start {
    justify-content: flex-start;
  }
  .is-aligned-start {
    align-items: flex-start;
  }
  .is-half {
    width: 50%;
  }
  .capital-header {
    color: $silver-chalice
  }
  .border-box {
    // display: flex;
    border: $silver-chalice 1px solid;
    padding: 5rem 0rem;
    flex-basis: 50%;
  }
  // .border-box:first-child {
  //   border-right: 0px
  // }
  .level-item {
    &.is-level-column {
      flex-direction: column;
      flex-shrink: 1;
    }
  }
  .footnote {
    padding: 0.25rem;
    transition: background 200ms linear, color 200ms linear;
  }
  .footnote:target {
    background: rgba($deep-sapphire, 0.07);
    border-radius: 2px;
    color: $deep-sapphire;
  }

  .notimplemented {
    background-color: yellow;
    color: red;
  }
</style>
