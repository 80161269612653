<template>
  <div class="user-picker">
    <label class="has-text-weight-bold is-uppercase is-size-7" for="trust-selector">Stiftelse</label>
    <model-select id="trust-selector"
                  :options="options"
                  :selected-options="[]"
                  v-model="value"
                  placeholder="Välj stiftelse" />
    <!-- <button @click.prevent="doAction" class="button is-uppercase is-size-7">Visa</button> -->
  </div>
</template>

<script>
import Vue from 'vue'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'

export default Vue.extend({
  name: 'TrustPicker',
  components: {
    ModelSelect
  },
  data () {
    return {
      value: this.$route.params.trust
    }
  },
  computed: {
    trusts () {
      return this.$store.state.profile.raw || []
    },
    options () {
      return this.trusts.reduce((arr, trust) => {
        arr.push({
          value: trust.TrustSlug,
          text: this.getTrustName(trust)
        })
        return arr
      }, [])
    }
  },
  methods: {
    doAction () {
      this.$router.push('/t/' + this.value)
    },
    getTrustName (trust) {
      switch (trust.TrustSlug) {
        case 'sgpflexskarp':
          return 'SGP Flex'
        case 'sgpskarpejigång':
          return 'SGP'
        default:
          return trust.TrustName
      }
    }
  },
  watch: {
    value () {
      this.doAction()
    }
  }
})
</script>

<style scoped lang="scss">
$deep-sapphire: #012169;
$deep-sapphire-hover: #002783;

.user-picker {
  width: 19em;
  margin-left: 2em;
  display: inline-block;
}
.user-picker label {
  letter-spacing: .05em;
}
.button {
  letter-spacing: .05em;

  background-color: $deep-sapphire;
  border-color: transparent;
  box-shadow: none;

  align-items: center;
  border: 1px solid transparent;
  border-radius: 0;
  height: 2.25em;
  line-height: 1.5;
  position: relative;
  vertical-align: top;

  margin-top: 0.75em;

  font-weight: 500;
  color: #fff;
}
.button:hover {
  color: #fff;
  background-color: $deep-sapphire-hover;
}
.button:focus, .button:active {
  color: #fff;
}
</style>
