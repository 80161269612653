<template>
  <div class="home">
    <LoginRequired />
    <div class="columns relative columns--full-height columns--vertical-center columns--no-vertical-margin columns--no-horizontal-margin">
      <div class="column is-6 column--no-horizontal-padding">
        <div class="back-button">
          <a href="https://stifta.se">
            <b-icon
              pack="fas"
              icon="chevron-left"
              size="is-small">
            </b-icon>
            Tillbaka till stifta.se
          </a>
        </div>
        <section v-if="$store.state.profile.loading" class="login">
          <div class="columns">
            <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
              <h1 class="title is-size-3">Laddar...</h1>
            </div>
          </div>
        </section>
        <section v-else class="login">
          <div class="columns">
            <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
              <template v-if="trusts && trusts.length > 0">
                <h1 class="title is-size-3 mb-1">Välj stiftelse</h1>
                <span>Välj den stiftelse som du vill se värdet på</span>
              </template>
              <h1 v-else class="title is-size-4">Du är inte registrerad hos Stifta.<br>Tror du att detta är ett fel? Kontakta Stifta</h1>
            </div>
          </div>
          <div class="select-trust columns">
            <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
              <ul>
                <li v-for="trust in trusts" :key="trust.TrustSlug">
                  <router-link :to="{name: 'MyPages', params: {trust: trust.TrustSlug} }">{{ getTrustName(trust) }}</router-link>
                </li>
              </ul>
              <button class="logout" v-on:click="logout()">Logga ut</button>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
              <footer class="login__footer has-text-centered">
                <a href="https://stifta.se">
                  <img src="@/assets/stifta.svg">
                </a>
              </footer>
            </div>
          </div>
        </section>
      </div>
      <div class="column column--full-height column--background-image is-6 is-hidden-mobile column--no-horizontal-padding">
        <div class="background-image background-image--full" v-bind:class="{ 'has-blur': false }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import LoginRequired from '@/components/LoginRequired'

export default Vue.extend({
  name: 'home',
  methods: {
    logout () {
      this.$store.dispatch('logout')
        .then(() => this.$router.go('/logout'))
    },
    getTrustName (trust) {
      switch (trust.TrustSlug) {
        case 'sgpflexskarp':
          return 'SGP Flex'
        case 'sgpskarpejigång':
          return 'SGP'
        default:
          return trust.TrustName
      }
    }
  },
  computed: {
    trusts () {
      return this.$store.state.profile.raw
    },
    error () {
      return this.$store.state.profile.error
    },
    account () {
      return (this.$store.getters.session != null) ? this.$store.getters.session.account : null
    }
  },
  components: { LoginRequired }
})
</script>

<style lang="scss">
  $deep-sapphire: #012169;

  body {
    min-height: 100vh;
  }
  .relative {
    position: relative;
  }
  .columns--full-height, .column--full-height {
    height: 100vh;
  }
  .columns--vertical-center {
    align-items: center;
  }
  .columns--no-vertical-margin {
    margin-bottom: 0;
    margin-top: 0;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
  .columns--no-horizontal-margin {
    margin-left: 0;
    margin-right: 0;
  }
  .column--no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .column--background-image {
    overflow: hidden;
  }
  .background-image {
    background: $deep-sapphire url('../assets/login-background.jpg') no-repeat center center / cover;
    transition: filter 200ms linear;
  }
  .background-image--full {
    width: 120%;
    height: 120%;
    margin-top: -10%;
    margin-left: -10%;
  }
  .has-blur {
    filter: blur(5px);
  }
  .login__footer {
    padding-top: 20px;
    img {
      max-height: 1.75rem;
    }
  }
  .hand {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 50vw;
    transform: translateX(100%);
    transition: all 300ms ease;
    &.in-view {
      transform: translateX(0%);
    }
    &.is-faded {
      opacity: 0;
    }
  }
  .back-button {
    position: absolute;
    top: 0;
    width: 50%;
    margin: 1rem;
    font-size: 0.875rem;
    .icon {
      position: relative;
      top: 3px;
    }
    .icon svg {
      height: 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    .back-button {
      position: static;
      width: 100%;
      text-align: center;
      font-size: 0.875rem;
      padding: 1rem 0 3rem 0;
      margin: 0;
    }
  }

  .select-trust {
    a {
      margin-bottom: 0.8rem;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      height: auto;
      display: flex;
      width: 100%;
      background-color: #012169;
      border-color: transparent;
      border-width: 1px;
      cursor: pointer;
      justify-content: center;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      padding-left: 0.75em;
      padding-right: 0.75em;
      border-radius: 4px;
      border: 1px solid transparent;
    }
    a:hover {
        background-color: #011d5c;
        border-color: transparent;
        color: #fff;
    }
    a:active {
        background-color: #011950;
        border-color: transparent;
        color: #fff;
    }
    .logout {
        background: none;
        border: none;
        cursor: pointer;
        margin: 0;
        padding: 0;
        color: #888;
        text-decoration: underline;
    }

  }
</style>
