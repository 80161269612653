<template>
  <div class="table-container">
        <div class="desktop-table">
            <table class="table is-full-width">
                <thead v-if="content.length > 0">
                    <tr>
                        <th v-for="header in filteredHeaders.desktop" :key="header.key" :class="['is-size-6', 'nowrap', 'has-text-weight-light', header.class]" :colspan="header.colspan">
                            <span>{{ header.title }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="content.length > 0">
                    <tr v-for="(row, i) in content" :key="'tbody' + i" :class="row['_meta'] ? row['_meta'].class : null">
                        <td v-for="header in filteredHeaders.desktop" :key="header.key + i" :class="row[header.key] ? row[header.key].class : null" :colspan="header.colspan">
                          <span class="is-block" style="white-space: normal;">
                              <router-link v-if="isLink(header, row[header.key])" class="is-link" :to="row[header.key]['href']">{{ row[header.key].value  }}</router-link>
                              <a v-else-if="isActAs(header, row[header.key])" class="is-link" v-actAs="row[header.key].user">{{ row[header.key].value  }}</a>
                              <span v-else-if="header.type === 'money'">
                                <span v-if="row[header.key].value === null">
                                  -
                                </span>
                                <span v-else class="droid-money" :style="{'background-color': parseFloat(row[header.key].value) > 0 ? '#E9FAE5' : '#FAE5E5', 'padding': '4px 7px', 'border-radius': '7px'}">
                                  <span v-if="parseFloat(row[header.key].value) > 0">{{ '+' }}</span>
                                  <i18n-n :value="Math.round(parseFloat(row[header.key].value))" format="currency" locale="sv-SE" decimals="0">
                                    <template v-slot:currency="slotProps"><span style="color: #aaa">{{ slotProps.currency }}</span></template>
                                    <template v-slot:fraction=""></template>
                                    <template v-slot:decimal=""></template>
                                    <!-- <template v-slot:fraction="slotProps"><span style="color: #aaa;">{{ slotProps.fraction }}</span></template>
                                    <template v-slot:decimal="slotProps"><span style="color: #aaa;">{{ slotProps.decimal }}</span></template> -->
                                  </i18n-n>
                                </span>
                                <!-- <template v-if="amountOfDecimals(row[header.key]) > 2">
                                  <br>
                                  <span style="color: #f28800;font-style: italic;font-size: 10px;">Observera avrundning</span>
                                </template> -->
                              </span>
                              <span v-else-if="header.type === 'bold-money'" class="has-text-weight-semibold">
                                <i18n-n :value="Math.round(parseFloat(row[header.key].value))" format="currency" locale="sv-SE">
                                  <template v-slot:currency="slotProps"><span style="color: #aaa">{{ slotProps.currency }}</span></template>
                                  <template v-slot:fraction=""></template>
                                  <template v-slot:decimal=""></template>
                                </i18n-n>
                              </span>
                              <span v-else-if="Array.isArray(row[header.key].value)">
                                <template v-for="(value, index) in row[header.key].value">
                                  <br v-if="index > 0" :key="index + 'br'">
                                  <span :key="index + 'span'">{{ value }}</span>
                                </template>
                              </span>
                              <span v-else>{{ row[header.key].value }}</span>
                          </span>
                        </td>
                    </tr>
                </tbody>
                <!-- <tbody v-else>
                    <tr>
                        <td colspan="9" class="has-text-centered"><b>{{ noResults }}</b></td>
                    </tr>
                </tbody> -->
            </table>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TableRender',
  props: {
    headers: Array,
    content: Array
  },
  computed: {
    filteredHeaders () {
      return {
        desktop: this.headers.filter((header) => {
          return !header.mobile_only
        }),
        mobile: this.headers.filter((header) => {
          return !header.desktop_only
        })
      }
    }
  },
  methods: {
    setOrderBy (header) {
      const filters = { ...(this.$route.query || {}) }
      filters.sort = (filters.orderby === header.order_by || (!filters.sort && header.order_by_this) ? (this.sort === 'desc' ? 'asc' : 'desc') : 'desc') // Invert current sort if it's activly being sorted by
      filters.orderby = header.order_by
      this.$router.replace({ query: filters })
      this.$emit('sortUpdate', this.searchTerms)
    },
    isLink (header, row) {
      return row.is_link || (row.is_link !== false && header.is_link)
    },
    isActAs (header, row) {
      return row.is_actAs || (row.is_actAs !== false && header.is_actAs)
    }
  }
}
</script>

<style>
  .droid-money * {
    font-family: DroidSansMonoSlashed;
    font-size: 12px
  }
  .table-container table td, .table-container table th {
    border: 0;
    padding-right: 2em
  }
</style>
