<template>
  <div>
    <div :class="['popup__backdrop', visible ? 'open' : null ]" />
    <div ref="popup" :class="['popup', 'is-size-6', visible ? 'open' : null ]">
        <div class="columns">
            <div class="column" style="margin: 2em">
                <div>Du kommer att bli utloggad om {{ timeleft }} sekunder.</div>
                <div class="field is-grouped mt-2">
                  <p class="control">
                    <button class="button is-medium is-primary" @click="refresh(true)">Fortsätt vara inloggad</button>
                  </p>
                  <p class="control">
                    <button class="button is-medium is-primary" @click="logout()">Logga ut</button>
                  </p>
                </div>
              </div>
        </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { mapActions } from 'vuex'
import _ from 'lodash'

export default Vue.extend({
  data () {
    return {
      refreshThrottle: _.throttle(this.refresh, 60 * 1000, { leading: true, trailing: true }),
      ttlMin: 60,
      timeleft: 60 * 4,
      timeout: null
    }
  },
  props: {
  },
  watch: {
    visible () {
      if (!this.visible) {
        return
      }
      this.$nextTick(() => {
        this.$refs.popup.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        })
      })
    },
    ttlMax: {
      immediate: true,
      handler () {
        if (this.ttlMax < this.timeleft) {
          this.timeleft = this.ttlMax
        }
      }
    },
    timeleft: {
      immediate: true,
      handler () {
        if (this.timeout) clearTimeout(this.timeout)
        if (this.timeleft > 0) {
          this.timeout = setTimeout(() => {
            this.timeleft = this.timeleft - 1
          }, 1000)
        } else {
          this.logout()
        }
      }
    }
  },
  computed: {
    ttlMax () {
      if (this.$store.getters.session) {
        return moment(this.$store.getters.session.tokenExpires).diff(moment(), 'seconds')
      }
      return 60 * 4
    },
    visible () {
      return this.timeleft < this.ttlMin && this.timeleft > 0
    }
  },
  methods: {
    ...mapActions(['refreshToken']),
    refresh (force) {
      if (force === true || this.timeleft > this.ttlMin) {
        this.refreshToken()
          .then(() => {
            this.timeleft = this.ttlMax
          })
      }
    },
    logout () {
      this.timeleft = 0
      this.$store.dispatch('logout')
        .then(() => this.$router.go('/logout'))
    }
  },
  created () {
    window.addEventListener('mousemove', this.refreshThrottle)
    window.addEventListener('keypress', this.refreshThrottle)
  }
})
</script>

<style lang="sass" scoped>
$deep-sapphire: #012169

.control .button.is-primary
    background-color: $deep-sapphire

.popup
    position: absolute
    left: 0
    top: 0
    background: #fff
    z-index: 50
    display: none
    position: fixed
    // width: 800px
    top: 50%
    left:  50%
    transform: translate(-50%, -50%)
    max-width: 100%
    &.open
        display: block
        animation: fadeIn 500ms

.popup__backdrop
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0,0,0,0.8)
    z-index: 50
    display: none
    &.open
        display: block
        animation: fadeIn 500ms

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

span.popup__open, .span.popup__open, .popup__vueopen
    cursor: pointer
    height: 0px

.control
    span.popup__open, .span.popup__open, .popup__vueopen
        pointer-events: all !important
        color: #58595b !important

</style>
