import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import MyPages from './views/MyPages.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/t/:trust',
      name: 'MyPages',
      component: MyPages
    },
    {
      path: '/om-oss',
      name: 'om-oss',
      component: () => import(/* webpackChunkName: "loggedin" */ './views/OmOss.vue')
    },
    {
      path: '/sparande',
      name: 'sparande',
      component: () => import(/* webpackChunkName: "loggedin" */ './views/Sparande.vue')
    },
    {
      path: '/t/:trust/pensionables',
      name: 'pensionables',
      component: () => import(/* webpackChunkName: "loggedin" */ './views/Pensionables.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "auth" */ './views/Login.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "auth" */ './views/Logout.vue')
    }
  ]
})
