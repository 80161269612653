import axios from 'axios'
import store from '@/store'

export default class ProfileAPI {
  constructor (accessToken) {
    const client = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL + '/profile',
      timeout: 60000,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    client.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    client.interceptors.request.use((config) => {
      config.headers.common.Authorization = `Bearer ${store.getters.accessToken}`
      return config
    })
    this.client = client
  }

  /**
   * Get all Profile data availible to us, based on what user we're authenticated as.
   */
  getAll () {
    return this.client.get('/all')
  }

  /**
   * Get all Profile data, based on what user we're authenticated as and supplied Trust Identifier / Personal Number.
   * @param {string} TrustSlug Slug of the Trust to fetch data from
   * @param {string} PersonalNumber Personal number from the Pensionable or Advisor to fetch data from
   */
  getFor (TrustSlug, PersonalNumber) {
    return this.client.post('/single', { TrustSlug, PersonalNumber })
  }
}
