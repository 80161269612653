import Vue from 'vue'
import Vuex from 'vuex'
import session from '@/store/session'
import profile from '@/store/profile'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session,
    profile
  }
})
