<template>
  <div class="user-picker">
    <label class="has-text-weight-bold is-uppercase is-size-7" for="selector">Användare</label>
    <model-select id="selector"
                  :options="uniqueOptions"
                  :selected-options="[]"
                  v-model="value"
                  placeholder="Välj användare" />
    <!-- <button @click.prevent="doAction" class="button is-uppercase is-size-7">Visa</button> -->
  </div>
</template>

<script>
import Vue from 'vue'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'

export default Vue.extend({
  name: 'ActAsUserPicker',
  components: {
    ModelSelect
  },
  data () {
    return {
      value: {}
    }
  },
  computed: {
    currentData () {
      if (!this.$store.state.profile.raw) {
        return null
      }
      return this.$store.state.profile.raw.find(r => {
        return r.TrustSlug === this.$route.params.trust
      })
    },
    users () {
      if (!this.currentData) {
        return []
      }
      return this.currentData.beneficiaries || []
    },
    delegates () {
      if (!this.currentData) {
        return []
      }
      return this.currentData.delegates || []
    },
    employerContactPersons () {
      if (!this.currentData || !this.currentData.adminUser) {
        return []
      }
      return this.currentData.adminUser.employerContactPersons || []
    },
    options () {
      return this.users.reduce((arr, user) => {
        arr.push({
          value: user.personalNumber,
          text: `${user.name} (${user.personalNumber})`
        })
        return arr
      },
      this.delegates.reduce((arr, user) => {
        arr.push({
          value: user.personalNumber,
          text: `${user.delegateName} (${user.personalNumber})`
        })
        return arr
      },
      this.employerContactPersons.reduce((arr, user) => {
        arr.push({
          value: user.personalNumber,
          text: `${user.name} (${user.personalNumber})`
        })
        return arr
      }, [])))
    },
    uniqueOptions () {
      const found = new Map()
      return this.options.filter(opt => {
        if (found.has(opt.value)) {
          return false
        }
        found.set(opt.value, true)
        return true
      })
    }
  },
  methods: {
    doAction () {
      this.$router.push('/t/' + this.$route.params.trust + '?p=' + this.value.value)
    }
  },
  watch: {
    value () {
      this.doAction()
    }
  }
})
</script>

<style scoped lang="scss">
$deep-sapphire: #012169;
$deep-sapphire-hover: #002783;

.user-picker {
  width: 19em;
  margin-left: 2em;
  display: inline-block;
}
.user-picker label {
  letter-spacing: .05em;
}
.button {
  letter-spacing: .05em;

  background-color: $deep-sapphire;
  border-color: transparent;
  box-shadow: none;

  align-items: center;
  border: 1px solid transparent;
  border-radius: 0;
  height: 2.25em;
  line-height: 1.5;
  position: relative;
  vertical-align: top;

  margin-top: 0.75em;

  font-weight: 500;
  color: #fff;
}
.button:hover {
  color: #fff;
  background-color: $deep-sapphire-hover;
}
.button:focus, .button:active {
  color: #fff;
}
</style>
