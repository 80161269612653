import ProfileAPI from '@/api/ProfileAPI'
import router from '@/router.js'

export default {
  state: {
    raw: [],
    apiClient: null,
    error: false,
    loading: false
  },
  mutations: {
    setRaw (state, raw) {
      state.raw = raw
    },
    setApiClient (state, profileApi) {
      state.apiClient = profileApi
    },
    setError (state, error) {
      state.error = error
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    reset (state) {
      state.raw = null
      state.selectedTrust = null
      state.apiClient = null
      state.loading = false
    }
  },
  actions: {
    fetchProfileData ({ commit, dispatch }) {
      var profileApi = new ProfileAPI(this.getters.accessToken)
      commit('setApiClient', profileApi)
      commit('setLoading', true)

      return profileApi.getAll()
        .then(res => {
          commit('setRaw', res.data.Trusts)
          if (res.data.Trusts.length === 1) {
            router.push('/t/' + res.data.Trusts[0].TrustSlug)
          }
        })
        .catch(err => {
          commit('setError', true)
          // TODO Fetch new access token using the refreshtoken
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch('logout')
          }
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    clearProfileData ({ commit }) {
      commit('reset')
      return Promise.resolve(true)
    },
    logout ({ commit, dispatch }) {
      dispatch('clearProfileData')
      return Promise.resolve(true)
    }
  }
}
