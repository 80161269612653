<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a href="https://stifta.se" class="navbar-item">
          <img src="@/assets/stifta.svg">
        </a>

        <div style="margin-left: auto">
          <TrustPicker v-if="isLoggedIn && $store.state.profile.raw && $store.state.profile.raw.length > 1" class="" />
          <ActAsUserPicker v-if="isLoggedIn && rawCurrentData && rawCurrentData.adminUser" class="" />
        </div>
        <a role="button" class="navbar-burger burger" v-on:click="showNav = !showNav" v-bind:class="{ 'is-active' : showNav, 'navbar-no-margin-left' : beneficiaries && beneficiaries.length > 0 }" aria-label="menu" v-bind:aria-expanded="showNav ? 'true' : 'false'" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="main-navbar" class="navbar-menu" v-bind:class="{ 'is-active' : showNav }">
        <div class="navbar-end">
          <router-link v-if="isLoggedIn && currentData && currentData.beneficiary" class="navbar-item" active-class="active" to="/">Mina Sidor</router-link>
          <router-link v-if="isLoggedIn && currentData && (currentData.delegate || (currentData.employerContactPerson && currentData.employerContactPerson.length > 0))" class="navbar-item" active-class="active" :to="pensionablesUrl">Pensionsberättigade</router-link>
          <!--
          <router-link v-if="isLoggedIn" class="navbar-item" active-class="active" to="/sparande">Ditt sparande</router-link>
          <router-link v-if="isLoggedIn" class="navbar-item" active-class="active" to="/blanketter">Mallar och blanketter</router-link>
          <router-link v-if="isLoggedIn" class="navbar-item" active-class="active" to="/konto">Mina uppgifter</router-link>
          -->
          <unleash-feature name="direktval">
            <a class="navbar-item" href="https://direktval.stifta.se/login" target="_BLANK">
              <font-awesome-icon :icon="['far', 'person-booth']" style="margin-right: 5px;" /> Val till styrelse
            </a>
          </unleash-feature>
          <a class="navbar-item" v-on:click="logout" v-if="isLoggedIn"><font-awesome-icon :icon="['far', 'user-circle']" style="margin-right: 5px;" /> Logga ut</a>
          <div class="navbar-item" v-if="!isLoggedIn">
            <router-link class="button is-primary" to="/login">Logga in</router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Vue from 'vue'
import ActAsUserPicker from '@/components/ActAsUserPicker'
import TrustPicker from '@/components/TrustPicker'

export default Vue.extend({
  name: 'app-header',
  components: {
    ActAsUserPicker,
    TrustPicker
  },
  data () {
    return {
      showNav: false
    }
  },
  methods: {
    logout () {
      this.showNav = false
      this.$store.dispatch('logout')
        .then(() => this.$router.go('/logout'))
    }
  },
  asyncComputed: {
    currentData: {
      async get () {
        if (!this.$store.state.profile.raw) {
          return null
        }
        const selectedTrust = this.$store.state.profile.raw.find(r => {
          return r.TrustSlug === this.$route.params.trust
        })

        if (this.$route.query.p) {
          const specTrust = await this.$store.state.profile.apiClient.getFor(this.$route.params.trust, this.$route.query.p)
          return specTrust.data
        }

        return selectedTrust
      },
      default () {
        return null
      }
    }
  },
  computed: {
    rawCurrentData () {
      if (!this.$store.state.profile.raw) {
        return null
      }
      return this.$store.state.profile.raw.find(r => {
        return r.TrustSlug === this.$route.params.trust
      })
    },
    pensionablesUrl () {
      let q = ''
      if (this.$route.query.p) {
        q += '?p=' + this.$route.query.p
      }
      return '/t/' + this.$route.params.trust + '/pensionables' + q
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    beneficiaries () {
      if (!this.currentData) {
        return []
      }
      return this.currentData.beneficiaries
    }
  },
  watch: {
    $route (to, from) {
      this.showNav = false
    }
  }
})
</script>

<style scoped lang="scss">
.navbar .navbar-brand {
  padding-top: 38px;
  padding-bottom: 38px;
  flex-grow: 1000;
}
.navbar-item {
  color: #000000;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.03rem;
}
.navbar-brand img {
  max-height: 36px;
}
.navbar-no-margin-left {
  margin-left: 0;
}
.active {
  color: #bbbcbd;
}
</style>
