<template>
  <div></div>
</template>

<script>
export default {
  name: 'login-required',
  beforeCreate () {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login')
    }
  },
  watch: {
    '$store.getters.isLoggedIn' () {
      if (!this.$store.getters.isLoggedIn) {
        this.$router.push('/login')
      }
    }
  }
}
</script>
