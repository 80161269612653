import 'es6-promise/auto'
import './polyfill'

import Vue from 'vue'
import VueSession from 'vue-session'
import Buefy from 'buefy'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import VueUnleash from './libs/vue-unleash'
import AsyncComputed from 'vue-async-computed'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faCheck,
  faExclamationTriangle,
  faChevronLeft,
  faUserCircle,
  faMinus,
  faPhoneAlt,
  faPersonBooth
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import decimal from 'decimal.js'
decimal.precision = 4
Object.defineProperty(Vue.prototype, '$decimal', { value: decimal })

Vue.config.productionTip = false

if (process.env.node_env === 'production') {
  Sentry.init({
    dsn: 'https://6bd0b6d7709945f694d1b59a92cdd798@sentry.duva.se/21',
    integrations: [new VueIntegration({ Vue, attachProps: true })]
  })
}

library.add(
  faUser,
  faCheck,
  faExclamationTriangle,
  faChevronLeft,
  faUserCircle,
  faMinus,
  faPhoneAlt,
  faPersonBooth,
  far,
  fal
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueSession, { persist: true })
Vue.use(Buefy, { defaultIconPack: 'fa', defaultIconComponent: 'font-awesome-icon' })
Vue.use(AsyncComputed)
Vue.use(VueUnleash, {
  appName: 'stifta-mina-sidor',
  instanceId: 'gSU1p6G1JQL66ng8xc3K',
  host: 'https://gitlab.duva.se/api/v4/feature_flags/unleash/2',
  store
})

Vue.filter('amountInCentstoDisplay', function (valueInCents) {
  if (typeof valueInCents !== 'number') {
    return null
  }
  var formatter = new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 0
  })
  return formatter.format(valueInCents / 100)
})

Vue.directive('actAs', {
  bind (el, binding) {
    el.actAsEvent = function () {
      store.commit('setBeneficiary', binding.value)
      router.push('/')
    }
    el.addEventListener('click', el.actAsEvent)
  },
  unbind (el) {
    el.removeEventListener('click', el.actAsEvent)
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
